import { isRouteErrorResponse, useRouteError } from "@remix-run/react";
import { ErrorContainer } from "./ErrorContainer";

export const AppErrorBoundary = () => {
  const error = useRouteError();
  console.log(
    "🚀 | AppErrorBoundary | error:",
    (error as Error).message || "unknown error",
    (error as Error)?.stack || "unknown stack"
  );
  let message = tryParseMessage(error as any);

  return (
    <ErrorContainer title="Something went wrong" className="m-4 md:m-6">
      <pre className="whitespace-pre-wrap">{message}</pre>
      <button onClick={() => window.location.reload()}>Try Again</button>
    </ErrorContainer>
  );
};

type ThrownErrorType =
  | string
  | Error
  | { status: number; data: any }
  // DIY Error
  | { message: string }
  // Diy error array
  | { message: string }[];

let tryParseMessage = (thrown: ThrownErrorType) => {
  if (!thrown) return "Unknown error";
  if (typeof thrown === "string") return thrown;
  if (isRouteErrorResponse(thrown)) {
    let status = thrown.status;
    let message = thrown.data?.message || thrown.data;
    return `${status}: ${message}`;
  }

  if ("message" in thrown) return thrown.message;
  if ("data" in thrown) {
    return thrown.data?.message || thrown.data;
  }
  if (Array.isArray(thrown)) {
    console.error(thrown);
    return thrown
      .map((e) => e?.message)
      .filter(Boolean)
      .join(", ");
  }

  return "Unknown error";
};
